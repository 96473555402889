import FooterNav from "../../../components/shared/navFooter.vue";


export default {
  components: {FooterNav},
  data() {
    return {
      pageLoaded: false,
      name: null,
      content: null,
      menuItems: []
    };
  },

  methods: {
    getContent() {
      this.api.content.getStaticPage(this.actualPath)
        .then(({data}) => {
          let result = data;
          this.pageLoaded = true;
          if (result.success) {
            this.name = result.data.page.name;
            this.content = result.data.page.content;
            this.menuItems = result.data.menu_items;
            this.$store.state.indexHTMLTitle = result.data.meta.title;
            this.$store.state.indexHTMLMetaDescription = result.data.meta.description;

            this.Helper.trackEvents.pageView(this.Helper.staticPageProps(result.data.page, this.$store.state.routerParams[0]));
          } else {
            this.$toasted.global.errorToast({description: result.message});
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
    }
  },

  computed: {
    actualPath() {
      return this.$store.state.routerParams[0];
    }
  },

  watch: {
    actualPath(newValue, oldValue) {
      if (newValue) {
        this.pageLoaded = false;
        this.getContent();
      }
    }
  },

  created() {
    this.pageLoaded = false;
    this.getContent();
  }
}
