<template>
  <div >
    <div style='position: fixed; left: 0; width: 33%;  background-color: #fff; z-index: -2;'></div>
    <div style='position: fixed; right: 0; width: 33%; height: 100%; background-color: #eff3f8; z-index: -1;'></div>
    <div class="pageContainer" style="display: flex; background-color: #eff3f8!important;">
      <div class="nav" >
        <div class="nav-menutext"></div>
        <div class="nav-items">
          <ul class="nav-list">
            <li v-for="(item, index) in menuItems">
              <router-link :to = "item.url" active-class="active">
                {{ item.name }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="nav-left-footer">
          <footer-nav></footer-nav>
        </div>
      </div>

      <div class="content" :style="{ 'min-height': `${windowHeight - 110}px` }">
        <p class="page-header" >{{ name }} </p>
        <div class="content-white" style="padding-top: 20px;">
          <div v-html="content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import mixin from './mixin';

  export default {
    name: "src-pages-body-staticContents-staticpages-v1",
    mixins: [mixin]
  }
</script>

<style scoped>

</style>
